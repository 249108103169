import { FC, useState } from 'react';
import { Popover } from '@headlessui/react';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { IService } from 'interfaces/services';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import CreateOrEditService, {
  CreateOrEditServiceSubmitData,
} from '../CreateOrEditService/CreateOrEditService';
import { loadSelectedClinicId } from 'utils/storage';
import axiosInstance from 'apis/axiosInstance';
import useMatchMutate from 'hooks/useMatchMutate';
import { renderToast } from 'components/Toast/Toast';
import { BAD_REQUEST } from 'utils/constants/statusCode';
import {
  convertDurationForBE,
  convertDurationForFE,
} from 'utils/convertDuration';

interface ThreeDotsOptionProps {
  service: IService;
  isSubRow: boolean;
  isParent: boolean;
  isHygieneChairSetup: boolean;
  timeInterval?: number;
}

const OPTIONS = {
  EDIT: { label: 'Edit', value: 'edit' },
  ACTIVATE: { label: 'Activate', value: 'activate' },
  DEACTIVATE: { label: 'Deactivate', value: 'deactivate' },
  DELETE: { label: 'Delete', value: 'delete' },
};

const { EDIT, ACTIVATE, DEACTIVATE, DELETE } = OPTIONS;

const ThreeDotsOption: FC<ThreeDotsOptionProps> = ({
  service,
  isSubRow,
  isParent,
  isHygieneChairSetup,
  timeInterval,
}) => {
  const clinicId = loadSelectedClinicId();

  const url = `clinics/${clinicId}/services/${service.id}`;
  const [confirmModal, setConfirmModal] = useState<typeof EDIT | null>(null);
  const matchMutate = useMatchMutate();

  const getOptions = () => {
    const options = [];

    if (!isParent) {
      options.push(EDIT);
    }

    if (isSubRow) {
      return options;
    }

    const statusOption = service.isActive ? DEACTIVATE : ACTIVATE;

    options.push(statusOption);

    if (service.isCustom) {
      options.push(DELETE);
    }

    return options;
  };

  const handleClickOption = (option: typeof EDIT) => {
    setConfirmModal(option);
  };

  const onClose = () => setConfirmModal(null);

  const onEditService = async (data: CreateOrEditServiceSubmitData) => {
    const payload: any = {
      name: data.name,
      duration: convertDurationForBE(data.duration!),
      isActive: data.status === 'active',
    };

    if (isSubRow) {
      payload.isSplitScheduling = data.isSplitScheduling;
    }

    if (isSubRow && data.isSplitScheduling) {
      payload.doctorDuration =
        data.doctorDuration !== null
          ? convertDurationForBE(data.doctorDuration!)
          : null;
      payload.hygienistDuration =
        data.hygienistDuration !== null
          ? convertDurationForBE(data.hygienistDuration!)
          : null;
      payload.examPlacement = data.examPlacement;
      payload.doctorIds = data.doctorIds;
    }

    const isAgeEditable = data.fromAge && data.toAge;

    if (isAgeEditable) {
      payload.ages = [data.fromAge, data.toAge];
    }

    try {
      await axiosInstance.patch(url, payload);

      await matchMutate(/\/services\?[\s\S]+/);

      renderToast({
        type: 'success',
        message: 'Updated a service successfully',
      });
      onClose();
    } catch (error: any) {
      if (error.response?.status === BAD_REQUEST) {
        return renderToast({
          message: error.response?.data.message,
          type: 'error',
        });
      }

      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  const onDeleteService = async () => {
    try {
      await axiosInstance.delete(url);

      await matchMutate(/\/services\?[\s\S]+/);
      renderToast({
        type: 'success',
        message: 'Deleted a service successfully',
      });
      onClose();
    } catch (error: any) {
      if (error.response?.status === BAD_REQUEST) {
        return renderToast({
          message: error.response?.data.message,
          type: 'error',
        });
      }

      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  const onEditServiceStatus = async () => {
    try {
      await axiosInstance.patch(url, {
        isActive: !service.isActive,
      });

      await matchMutate(/\/services\?[\s\S]+/);
      renderToast({
        type: 'success',
        message: `${confirmModal?.label} a service successfully`,
      });
      onClose();
    } catch (error: any) {
      if (error.response?.status === BAD_REQUEST) {
        return renderToast({
          message: error.response?.data.message,
          type: 'error',
        });
      }

      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  return (
    <>
      {confirmModal === EDIT && (
        <CreateOrEditService
          onClose={onClose}
          onSubmit={onEditService}
          defaultValues={{
            name: service.name,
            duration: convertDurationForFE(service.duration),
            status: service.isActive ? 'active' : 'inactive',
            ...(isSubRow && {
              doctorDuration: service.doctorDuration,
              hygienistDuration: service.hygienistDuration,
              isSplitScheduling: service.isSplitScheduling,
              examPlacement: service.examPlacement,
              fromAge: service.fromAge,
              toAge: service.toAge,
            }),
          }}
          isNameDisabled={!service.isCustom}
          isExamCleaningService={isSubRow}
          timeInterval={timeInterval}
          serviceId={service.id}
        />
      )}
      {confirmModal === DELETE && (
        <ConfirmationModal
          title="Delete service?"
          description={`Are you sure you want to delete ${service.name}`}
          onClose={onClose}
          onSubmit={onDeleteService}
          submitBtnTitle={`Yes, delete`}
        />
      )}
      {(confirmModal === ACTIVATE || confirmModal === DEACTIVATE) && (
        <ConfirmationModal
          title={`${confirmModal.label} service?`}
          description={`Are you sure you want to ${confirmModal.value} ${service.name}`}
          onClose={onClose}
          onSubmit={onEditServiceStatus}
          submitBtnTitle={`Yes, ${confirmModal.value}`}
        />
      )}
      <Popover className="relative hidden group-hover:block">
        <Popover.Button className="flex items-center disabled:cursor-not-allowed disabled:opacity-30">
          <MoreIcon className="h-2.2" />
        </Popover.Button>
        <Popover.Panel className="flex flex-col gap-y-1.6 absolute py-2 right-0 z-10 bg-white shadow-primary rounded-[0.8rem] mt-0.6 w-16.5">
          {getOptions().map((option) => (
            <Popover.Button
              key={option.value}
              className="w-full px-2 hover:bg-opacity-10 hover:text-magenta text-left disabled:text-grey"
              onClick={() => handleClickOption(option)}
              disabled={isParent && !isHygieneChairSetup}
            >
              {option.label}
            </Popover.Button>
          ))}
        </Popover.Panel>
      </Popover>
    </>
  );
};

export default ThreeDotsOption;
