import { FC } from 'react';
import { IPractitionerHeaderCellProps } from '../HeaderCell';
import { IMapPractitionerColor } from 'pages/SchedulePage/utils';
import {
  NO_APPOINTMENT_ID,
  PRACTICE_OFF,
  PRACTITIONER_OFF,
  PRACTITIONER_WITH_NO_APPOINTMENT,
  PRACTITIONER_WITH_NO_OPERATORY,
} from '../../utils';
import AvatarPlaceHolder from 'assets/images/no-avatar.svg';
import { Operatory } from 'interfaces/operatory';
import styles from '../View.module.css';
import ReactTooltip from 'react-tooltip';
import getPractitionerWorkingHour from '../utils/getPractitionerWorkingHour';
import getPractitionerName from 'utils/getPractitionerName';
import { DoctorSchedule } from 'interfaces/clinicSchedule';

interface PractitionerHeaderCellProps {
  headerCellItem: IPractitionerHeaderCellProps;
  practitioners?: IMapPractitionerColor[];
  operatories: Operatory[];
  date: string;
  workingHourPractitioners: (DoctorSchedule & { name: string })[];
}

const PractitionerHeaderCell: FC<PractitionerHeaderCellProps> = ({
  headerCellItem,
  practitioners,
  operatories,
  date,
  workingHourPractitioners,
}) => {
  const parentHeaderCell = document.querySelector('div[data-header-cell=true]');

  const practitioner = practitioners?.find(
    (item) => item.id === headerCellItem.id
  );

  const getIsOpAssignedPractitionerFromFirstIn = (operatory: Operatory) => {
    return practitioner?.operatories?.some((item) => item.id === operatory.id);
  };

  const operatoriesAssignedPractitioner =
    operatories.filter((operatory) =>
      getIsOpAssignedPractitionerFromFirstIn(operatory)
    ) ?? [];

  if (
    headerCellItem.id === NO_APPOINTMENT_ID ||
    headerCellItem.id === PRACTITIONER_WITH_NO_OPERATORY ||
    headerCellItem.id === PRACTITIONER_OFF ||
    headerCellItem.id === PRACTITIONER_WITH_NO_APPOINTMENT ||
    headerCellItem.id === PRACTICE_OFF
  ) {
    return (
      <div className="text-center w-full h-full font-bold text-[12px] flex justify-center items-center">
        N/A
      </div>
    );
  }

  const getOperatoriesAssignedPractitioner = () => {
    if (!parentHeaderCell) return operatoriesAssignedPractitioner;

    const paddingY = 16;
    const gap = 16;
    const numberOfItem = operatoriesAssignedPractitioner.length;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    context.font = '10px Nexa';
    const durationTextWidth = context.measureText(
      getPractitionerWorkingHour(
        practitioner?.id,
        workingHourPractitioners,
        true
      )
    ).width;
    const parentWidth =
      parentHeaderCell.clientWidth - durationTextWidth - paddingY - gap;

    let currentWidth = 0;
    for (let i = 0; i < numberOfItem; i++) {
      const operatory = operatoriesAssignedPractitioner[i];
      const text = `${operatory.name},`;
      const width = context.measureText(text).width;
      const gap = 3;
      currentWidth = currentWidth + width + gap;
      if (currentWidth > parentWidth) {
        return i === 0
          ? [
              {
                id: 'none',
                avatar: 'none',
                name: `${text.substring(0, 2)}...`,
                colorCode: 'none',
              },
            ]
          : [
              ...operatoriesAssignedPractitioner.slice(0, i),
              {
                id: 'none',
                avatar: 'none',
                name: `+${numberOfItem - i}`,
                colorCode: 'none',
              },
            ];
      }
    }
    return operatoriesAssignedPractitioner;
  };

  const updatedOperatoriesAssignedPractitioner =
    getOperatoriesAssignedPractitioner();

  return (
    <div className="flex flex-col px-0.8 h-full leading-[18px] overflow-hidden">
      <div className="flex flex-1 gap-x-0.8 items-center">
        <img
          src={headerCellItem.avatar || ''}
          alt={headerCellItem.name}
          className="rounded-full w-2 h-2 shrink-0"
          onError={(event) => {
            event.currentTarget.src = AvatarPlaceHolder;
          }}
        />
        <span className={`text-[11px] font-bold ${styles.truncateName}`}>
          {getPractitionerName(practitioner!)}
        </span>
      </div>

      <div className="flex text-[10px] basis-[1.8rem]">
        <span
          className="shrink-0 cursor-pointer mr-1"
          data-for={`operatories-practitioner-${headerCellItem.id}`}
          data-tip=""
        >
          {getPractitionerWorkingHour(
            practitioner?.id,
            workingHourPractitioners,
            true
          )}
        </span>
        {operatoriesAssignedPractitioner.length > 0 && (
          <div
            className="flex gap-x-0.3 cursor-pointer"
            data-for={`operatories-practitioner-${headerCellItem.id}`}
            data-tip=""
          >
            {updatedOperatoriesAssignedPractitioner.map((operatory, index) =>
              operatory.id === 'none' ? (
                <span
                  key={operatory.id}
                  className="text-[10px] text-darkest-grey shrink-0"
                >
                  {operatory.name}
                </span>
              ) : (
                <span
                  key={operatory.id}
                  className="text-[10px] text-darkest-grey shrink-0"
                >
                  {operatory.name}
                  {index === operatoriesAssignedPractitioner.length - 1
                    ? ''
                    : ','}
                </span>
              )
            )}
          </div>
        )}
      </div>
      <ReactTooltip
        id={`operatories-practitioner-${headerCellItem.id}`}
        effect="solid"
        place="bottom"
        className="!bg-white !rounded-[0.8rem] !w-auto !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:hidden !px-1 text-darkest-grey"
      >
        <div className="flex flex-col gap-x-0.6 text-darkest-grey text-11">
          <span>
            Working Hours:{' '}
            {getPractitionerWorkingHour(
              practitioner?.id,
              workingHourPractitioners
            )}
          </span>
          <div className="flex gap-x-0.3">
            {operatoriesAssignedPractitioner.map((operatory, index) => (
              <div className="flex items-center" key={operatory.id}>
                <span key={operatory.id}>
                  {operatory.name}
                  {index === operatoriesAssignedPractitioner.length - 1
                    ? ''
                    : ','}
                </span>
              </div>
            ))}
          </div>
        </div>
      </ReactTooltip>
    </div>
  );
};

export default PractitionerHeaderCell;
