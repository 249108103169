import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { AxiosRequestConfig } from 'axios';
import { loadSelectedClinicId } from 'utils/storage';

export type IAppointmentStatus =
  | 'new'
  | 'accepted'
  | 'error'
  | 'accepted-scheduled'
  | 'missed'
  | 'completed'
  | 'cancelled'
  | 'all'
  | 'accepted-error';

interface IUseAppointmentParams {
  shouldRun?: boolean;
  limit?: number;
  page?: number;
  sort?: 'asc' | 'desc';
  sortBy?: string;
  startDate?: string;
  endDate?: string;
  startAppointmentDate?: string;
  endAppointmentDate?: string;
  status?: IAppointmentStatus;
  pmsType?: 'dentrix' | 'firstin' | 'nexhealth';
  axiosRequestConfig?: AxiosRequestConfig;
}

const useAppointment = ({
  limit = 5,
  page = 1,
  sort = 'desc',
  sortBy = 'createdAt',
  status = 'new',
  pmsType = 'firstin',
  startDate,
  endDate,
  startAppointmentDate,
  endAppointmentDate,
  shouldRun = true,
  axiosRequestConfig,
  ...rest
}: IUseAppointmentParams) => {
  const params = {
    limit: limit.toString(),
    page: page.toString(),
    sort,
    sortBy,
    pmsType,
    ...(status !== 'all' && { status }),
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
    ...(startAppointmentDate && { startAppointmentDate }),
    ...(endAppointmentDate && { endAppointmentDate }),
    ...rest,
  };

  const query = new URLSearchParams(params).toString();
  const url = `/appointments?${query}`;
  const clinicId = loadSelectedClinicId();

  const { data, error, mutate } = useSWR(
    shouldRun ? [url, clinicId, axiosRequestConfig?.headers] : false,
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get(url, {
        ...axiosRequestConfig,
      });

      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useAppointment;
