import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import type { Location } from 'react-router-dom';
import moment from 'moment';

import { IAppointmentDetail } from 'interfaces/appointments';
import { calculateRangeBlock } from './getTimeBlockFromTImeString';
import { ROUTES } from './constants/routes';
import { APPOINTMENT_STATUS, ERROR_REASON, WRITE_STATUS } from './constants';

export const capitalizeAvatarName = (input: string | undefined) => {
  if (!input) return;
  const fullName = input.split(' ');
  if (fullName.length < 2) return;
  const [firstName, lastName] = fullName;
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

export const highlightText = (text: string, inputValue: string) => {
  const matches = match(text, inputValue, {
    findAllOccurrences: true,
    insideWords: true,
  });
  const parts = parse(text, matches);

  return parts;
};

export const isInAdminPage = (location: Location) => {
  return location.pathname.search(/\/admin\/.*/) !== -1;
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const calculateAge = (
  dob: string | null,
  format: string | string[] = ['YYYY-MM-DD']
) => {
  if (dob && moment(dob, format).isValid()) {
    const currentDate = moment().format('YYYY-MM-DD');
    return Math.abs(
      moment(currentDate, 'YYYY-MM-DD').diff(moment(dob, format), 'years')
    );
  }

  return '';
};

const isWriteFailError = <
  K extends { errorReason: string },
  T extends K & {
    isSplitScheduling: boolean;
    linkedAppointment: K | null;
  }
>(
  appointment: T
) => {
  let { errorReason, linkedAppointment, isSplitScheduling } = appointment;

  if (!isSplitScheduling) {
    return false;
  }

  const isValidPath =
    window.location.pathname.includes(ROUTES.APPOINTMENT) ||
    window.location.pathname.includes(ROUTES.DASHBOARD);

  const isFailedToWrite =
    linkedAppointment?.errorReason === ERROR_REASON.WRITE_FAIL ||
    errorReason === ERROR_REASON.WRITE_FAIL;

  return isValidPath && isFailedToWrite;
};

export const getErrorReason = <
  K extends { errorReason: string },
  T extends K & {
    isSplitScheduling: boolean;
    linkedAppointment: K | null;
  }
>(
  appointment: T
) => {
  let { errorReason, isSplitScheduling, linkedAppointment } = appointment;

  const isWriteFail = isWriteFailError(appointment);

  if (isWriteFail) {
    return ERROR_REASON.WRITE_FAIL;
  }

  if (!errorReason && isSplitScheduling && linkedAppointment?.errorReason) {
    errorReason = linkedAppointment.errorReason;
  }

  return errorReason;
};

export const getIsWarningIconEnabled = <
  T extends {
    isSplitScheduling: boolean;
    writeStatus: string;
    linkedAppointment: T | null;
    errorReason: string;
  }
>(
  appointment?: T | null
) => {
  if (!appointment) {
    return false;
  }

  let { writeStatus, linkedAppointment, isSplitScheduling } = appointment;

  let isWriteStatusPending = writeStatus === WRITE_STATUS.PENDING;

  if (!isWriteStatusPending && isSplitScheduling && linkedAppointment) {
    isWriteStatusPending =
      linkedAppointment?.writeStatus === WRITE_STATUS.PENDING;
  }

  const finalErrorReason = getErrorReason(appointment);

  // APPOINTMENT WITH SPECIFIC ERROR
  const isDuplicateOrGeneralError = [
    ERROR_REASON.DUPLICATE_BOOKING,
    ERROR_REASON.GENERAL_ERROR,
  ].includes(finalErrorReason);

  if (isDuplicateOrGeneralError && isWriteStatusPending) {
    return false;
  }

  // NORMAL + OTHER ERROR DEPEND ON WRITE STATUS
  return isWriteStatusPending;
};

// For example: [1,2,3,5,6] => [[1,2,3], [5,6]]
export const groupConsecutiveNumber = (numbers: number[]) => {
  const result = numbers.reduce((accum: number[][], currentValue: number) => {
    const tmp = accum[accum.length - 1];

    if (!tmp || tmp[tmp.length - 1] !== currentValue - 1) {
      accum.push([]);
    }

    accum[accum.length - 1].push(currentValue);

    return accum;
  }, []);

  return result;
};

export const generateBlockNumbers = (
  events: {
    fromTime: string;
    toTime: string;
  }[]
) => {
  const blockNumbers = events.reduce((accum: number[], currentEvent) => {
    const blockNumbers = calculateRangeBlock(
      currentEvent.fromTime,
      currentEvent.toTime
    );
    accum = accum.concat(blockNumbers);

    return accum;
  }, []);

  return blockNumbers;
};

export const getOptionsForAcceptedStatus = (errorReason: string) => {
  const {
    DUPLICATE_BOOKING,
    OUT_OF_OFFICE_HOURS,
    OUT_OF_PRACTITIONER_HOURS,
    PARTIAL_PATIENT_MATCH,
    GENERAL_ERROR,
    PATIENT_NEED_ADD_TO_FAMILY,
    WRITE_FAIL,
    UNFOUNDED_AVAILABLE_PRACTITIONER,
  } = ERROR_REASON;

  const options = ['Reject'];

  if (errorReason === GENERAL_ERROR || errorReason === WRITE_FAIL) {
    return options;
  }

  if (errorReason === PARTIAL_PATIENT_MATCH) {
    options.unshift('Select matching patient');
  }

  if (
    errorReason === DUPLICATE_BOOKING ||
    errorReason === OUT_OF_OFFICE_HOURS ||
    errorReason === OUT_OF_PRACTITIONER_HOURS ||
    errorReason === PATIENT_NEED_ADD_TO_FAMILY ||
    errorReason === UNFOUNDED_AVAILABLE_PRACTITIONER
  ) {
    options.unshift('Modify Appointment');
  }

  return options;
};

export const getOptionsForNewStatus = ({
  errorReason,
  currentStatus,
  isAppointmentInThePast,
}: {
  errorReason: string;
  currentStatus: string;
  isAppointmentInThePast: boolean;
}) => {
  const {
    DUPLICATE_BOOKING,
    OUT_OF_OFFICE_HOURS,
    OUT_OF_PRACTITIONER_HOURS,
    PARTIAL_PATIENT_MATCH,
    GENERAL_ERROR,
    PATIENT_NEED_ADD_TO_FAMILY,
    WRITE_FAIL,
    UNFOUNDED_AVAILABLE_PRACTITIONER,
  } = ERROR_REASON;

  if (isAppointmentInThePast) {
    return ['Accept'];
  }

  if (
    errorReason === OUT_OF_OFFICE_HOURS ||
    errorReason === OUT_OF_PRACTITIONER_HOURS ||
    errorReason === UNFOUNDED_AVAILABLE_PRACTITIONER ||
    errorReason === PATIENT_NEED_ADD_TO_FAMILY
  ) {
    return ['Accept', 'Modify Appointment', 'Reject'];
  }

  const options = ['Reject'];

  if (!errorReason && currentStatus !== APPOINTMENT_STATUS.ACCEPTED.KEY) {
    options.unshift('Modify Appointment');
    options.unshift('Accept');
  }

  if (errorReason === DUPLICATE_BOOKING) {
    options.unshift('Modify Appointment');
  }
  if (errorReason === PARTIAL_PATIENT_MATCH) {
    options.unshift('Select matching patient');
  }
  if ([GENERAL_ERROR, WRITE_FAIL].includes(errorReason)) {
    options.unshift('Accept');
  }

  return options;
};

export const filterOptionsByAcceptedOrCancelled = ({
  appointmentDetails,
  options,
}: {
  appointmentDetails: IAppointmentDetail;
  options: string[];
}) => {
  let tmpOptions = [...options];

  if (appointmentDetails.isAccepted) {
    tmpOptions = tmpOptions.filter((item) => item !== 'Accept');
  }

  if (appointmentDetails.isCancelled) {
    tmpOptions = tmpOptions.filter((item) => item !== 'Reject');
  }

  return tmpOptions;
};
