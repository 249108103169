import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Table from './Table/Table';
import { IAppointmentStatus } from 'hooks/useAppointment';

import AppointmentStatus from 'components/AppontmentStatus/AppointmentStatus';
import {
  CALENDAR_TYPE,
  getDateRangeByCalendarType,
} from 'utils/getDateRangeByCalendarType';
import AppointmentDateRange from 'components/AppointmentDateRange/AppointmentDateRange';
import { clearHistoryLocation, loadSelectedClinicId } from 'utils/storage';
import ActionNavigation from './ActionNavigation/ActionNavigation';
import ErrorNavigation from 'pages/DashboardPage/ErrorNavigation/ErrorNavigation';
import useAppointmentSearchParam from 'hooks/useAppointmentSearchParam';
import { useLocation } from 'react-router-dom';

const defaultTableSortState = {
  sortBy: 'appointmentDate',
  sort: 'desc' as const,
};

const AppointmentPage = () => {
  const {
    isActionParamCombinationValid,
    isErrorParamCombinationValid,
    params: { appointmentId },
  } = useAppointmentSearchParam();

  const location = useLocation();

  const isAcceptedStatus = location.search.includes('tab=accepted');

  const [selectedStatus, setSelectedStatus] = useState<IAppointmentStatus>(
    isAcceptedStatus ? 'accepted' : 'new'
  );

  const [sort, setSort] = useState<'asc' | 'desc'>(defaultTableSortState.sort);

  const [sortBy, setSortBy] = useState(defaultTableSortState.sortBy);

  const selectedClinicId = loadSelectedClinicId();

  const [selectedCalendarType, setSelectedCalendarType] = useState(
    CALENDAR_TYPE.THIS_MONTH
  );

  const [selectedDateRange, setSelectedDateRange] = useState<{
    fromDate: string;
    toDate: string;
  }>(
    () =>
      getDateRangeByCalendarType(CALENDAR_TYPE.THIS_MONTH) as {
        fromDate: string;
        toDate: string;
      }
  );

  useEffect(() => {
    const clearStorage = () => clearHistoryLocation();
    window.addEventListener('beforeunload', clearStorage);
    return () => {
      window.removeEventListener('beforeunload', clearStorage);
    };
  }, []);

  useEffect(() => {
    return () => toast.remove();
  }, []);

  return (
    <>
      {isActionParamCombinationValid && <ActionNavigation />}
      {isErrorParamCombinationValid && (
        <ErrorNavigation appointmentId={appointmentId!} />
      )}
      <div className="pt-2.4 pl-1.8 pr-4 text-14 leading-[2.1rem] text-darkest-grey pb-12">
        <div className="flex flex-row justify-between">
          <h3 className="text-24 font-bold leading-[3.6rem]">Appointments</h3>
        </div>

        <div className="my-2.4 flex gap-x-1.6 items-end justify-between">
          <AppointmentStatus
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
          />

          <AppointmentDateRange
            clinicId={selectedClinicId}
            dateRange={selectedDateRange}
            onChangeDateRange={setSelectedDateRange}
            selectedCalendarType={selectedCalendarType}
            setSelectedCalendarType={setSelectedCalendarType}
          />
        </div>

        <div className="bg-white shadow-primary rounded-[1rem] mt-2.4">
          <Table
            key={`${selectedStatus}${selectedDateRange.fromDate}${selectedDateRange.toDate}`}
            sort={sort}
            setSort={setSort}
            dateRange={selectedDateRange}
            sortBy={sortBy}
            setSortBy={setSortBy}
            status={selectedStatus}
          />
        </div>
      </div>
    </>
  );
};

export default AppointmentPage;
