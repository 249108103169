import React, { FC, useEffect, useRef, useState } from 'react';
import { RadioGroup } from '@headlessui/react';

import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import PatientCard from './PatientCard/PatientCard';

import usePartialPatient from 'hooks/usePartialPatient';
import { IPatient } from 'interfaces/patients';
import RadioButton from 'components/RadioButton/RadioButton';
import axiosInstance from 'apis/axiosInstance';

import { datetimeFormat } from 'utils/datetime';
import useMatchMutate from 'hooks/useMatchMutate';
import useAppointmentDetail from 'hooks/useAppointmentDetail';
import {
  ErrorAppointmentStatusType,
  ERROR_APPOINTMENT_STATUS,
  ERROR_REASON,
  ERROR_RESPONSE_SERVER,
} from 'utils/constants';
import { renderToast } from 'components/Toast/Toast';
import { getAppointmentStartTime } from 'components/TableColumn/utils/getAppointmentTime';
import EmptyPartialPatient from './EmptyPartialPatient/EmptyPartialPatient';
import useModal from 'hooks/useModal';

interface PartialPatientProps {
  id: string;
  onClose: () => void;
  onAppointmentAlreadyResolved: () => void;
  onClickAccept?: () => void;
}

const PartialPatient: FC<PartialPatientProps> = ({
  id,
  onClose,
  onAppointmentAlreadyResolved,
  onClickAccept,
}) => {
  const { data: appointmentDetail, isLoading: isAppointmentDetailLoading } =
    useAppointmentDetail(id);

  const { data: partialPatients, isLoading: isPartialPatientLoading } =
    usePartialPatient(appointmentDetail?.patient?.id);

  const matchMutate = useMatchMutate();

  const [selectedPatient, setSelectedPatient] = useState<IPatient>();
  const [isOverflow, setIsOverflow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isDataLoading =
    isAppointmentDetailLoading ||
    isPartialPatientLoading ||
    (!selectedPatient && partialPatients?.length !== 0);

  const { setIsAllowed } = useModal({ onClose, isLoading: isDataLoading });

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!selectedPatient && partialPatients) {
      const firstNormalPatient = partialPatients.find(
        (item) => !item.pmsPatient.isNewPatient
      );

      if (firstNormalPatient) {
        setSelectedPatient(firstNormalPatient);
      } else {
        setSelectedPatient(partialPatients[0]);
      }
    }
  }, [selectedPatient, partialPatients]);

  useEffect(() => {
    const element = containerRef.current;
    if (element && !isDataLoading) {
      const isOverflow =
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth;
      setIsOverflow(isOverflow);
    }
  }, [isDataLoading]);

  const renderErrorMessage = (message: string) => {
    const content = (
      <>
        This partial patient match error for{' '}
        <strong>{appointmentDetail.patient.name}</strong> has been resolved.{' '}
        {message}. Please click on the error icon to resolve the issue
      </>
    );
    renderToast({ type: 'warning', message: content });
  };

  const handleOnSubmit = async () => {
    const {
      SUCCESS,
      ALREADY_RESOLVED,
      DUPLICATE,
      ERROR,
      OUT_OF_OFFICE_HOURS,
      OUT_OF_PRACTITIONER_HOURS,
      GENERAL_ERROR,
      UNFOUNDED_AVAILABLE_PRACTITIONER,
    } = ERROR_APPOINTMENT_STATUS;
    let status: ErrorAppointmentStatusType = SUCCESS;

    try {
      setIsSubmitting(true);
      setIsAllowed(false);

      const earliestApptStartTime = getAppointmentStartTime(appointmentDetail);

      const formattedStartTime = datetimeFormat({
        dateString: earliestApptStartTime,
        format: 'hh:mm A',
        pattern: 'HH:mm:ss',
      });

      await axiosInstance.patch('/bookings', {
        appointmentId: appointmentDetail.id,
        patientId: selectedPatient!.id,
        doctorId: appointmentDetail.doctor.id,
        appointmentDate: appointmentDetail.appointmentDate,
        startTime: formattedStartTime,
        operatoryId: appointmentDetail.operatory.id,
      });
    } catch (error: any) {
      if (error.response?.data?.message === ERROR_REASON.DUPLICATE_BOOKING) {
        status = DUPLICATE;
      } else if (
        error.response?.data?.message === ERROR_REASON.OUT_OF_OFFICE_HOURS
      ) {
        status = OUT_OF_OFFICE_HOURS;
      } else if (
        error.response?.data?.message === ERROR_REASON.OUT_OF_PRACTITIONER_HOURS
      ) {
        status = OUT_OF_PRACTITIONER_HOURS;
      } else if (
        error.response?.data?.message ===
        ERROR_RESPONSE_SERVER.ALREADY_RESOLVED_MESSAGE
      ) {
        status = ALREADY_RESOLVED;
      } else if (error.response?.data?.message === ERROR_REASON.GENERAL_ERROR) {
        status = GENERAL_ERROR;
      } else if (
        error.response?.data?.message ===
        ERROR_REASON.UNFOUNDED_AVAILABLE_PRACTITIONER
      ) {
        status = UNFOUNDED_AVAILABLE_PRACTITIONER;
      } else if (
        error.response?.data?.message ===
        ERROR_RESPONSE_SERVER.APPOINTMENT_NOT_SYNC_WITH_PMS
      ) {
        status = ERROR;
        renderToast({
          message:
            'It looks like the slot has not been written into PMS. Please wait 5-10 minutes for the synchronization to complete, then reload the page.',
          type: 'error',
        });
      } else {
        status = ERROR;
        renderToast({
          message: 'An error has occurred. Please try again.',
          type: 'error',
        });
      }
    }
    setIsAllowed(true);
    setIsSubmitting(false);
    if (status === ERROR) return;
    if (status === SUCCESS) {
      renderToast({
        message: 'Appointment updated successfully',
        type: 'success',
      });
    }

    if (status === DUPLICATE) {
      renderErrorMessage('However duplicate appointment error persists');
    }
    if (status === OUT_OF_OFFICE_HOURS) {
      renderErrorMessage(
        'However the appointment is scheduled outside of practice’s hours'
      );
    }
    if (status === OUT_OF_PRACTITIONER_HOURS) {
      renderErrorMessage(
        'However the appointment is scheduled outside of practitioner’s hours'
      );
    }
    if (status === UNFOUNDED_AVAILABLE_PRACTITIONER) {
      renderErrorMessage('However no available practitioner found');
    }

    if (status === ALREADY_RESOLVED) {
      onAppointmentAlreadyResolved();
    }

    onClose();
    await matchMutate(/\/appointments\?[\s\S]+/);
  };

  const handleOnClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  const isDisabledMatchPatientBtn = (partialPatients || []).every((item) => {
    return item.pmsPatient.isNewPatient === true;
  });

  const renderEmptyPartialPatientMatch = () => {
    return (
      <div className="w-[57rem] h-[42rem] p-3 pr-4 text-darkest-grey text-14 normal-case flex flex-col justify-between">
        <h3 className="font-bold text-20 leading-[3rem]">
          Partial patient match
        </h3>
        <EmptyPartialPatient />

        <div className="flex justify-end gap-x-1.6">
          <CommonButton onClick={onClickAccept}>Accept</CommonButton>
        </div>
      </div>
    );
  };

  const renderNonEmptyPartialPatientMatch = () => {
    return (
      <div className="w-[57rem] h-[72.2rem] p-3 pr-4 text-darkest-grey text-14 normal-case">
        <h3 className="font-bold text-20 leading-[3rem]">
          Partial patient match
        </h3>
        <p className="leading-[2.1rem] mt-1.6">
          We found a patient that shares similar attributes. Is this the same
          patient? If so, please select the matching patient below
        </p>
        <div className="flex justify-center mt-1.6">
          <PatientCard
            fullName={appointmentDetail.patient.name}
            firstName={appointmentDetail.patient.firstName}
            lastName={appointmentDetail.patient.lastName}
            dob={appointmentDetail.patient.dob}
            email={appointmentDetail.patient.email}
            phoneNumber={appointmentDetail.patient.phoneNumber}
          />
        </div>
        <h1 className="font-bold mt-1.6">Please select a matching patient</h1>
        <span className="mt-0.3 text-12 leading-[1.8rem]">
          {partialPatients!.length}{' '}
          {partialPatients!.length > 1 ? 'matches' : 'match'} found
        </span>
        <div className="relative">
          {isOverflow && (
            <div className="h-[4.5rem] bg-blur-bg-bottom-2 absolute bottom-0 z-10 w-[calc(100%-1.6rem)] " />
          )}
          <div
            className="h-[33rem] overflow-y-auto scrollbar mt-1.6 pt-0.5 pb-2"
            ref={containerRef}
          >
            <div className="flex flex-col items-center justify-center gap-y-1.6">
              {partialPatients!.map((patient) => {
                const isNewPatient = patient.pmsPatient.isNewPatient || false;

                return (
                  <RadioGroup
                    key={patient.id}
                    value={selectedPatient}
                    onChange={!isNewPatient ? setSelectedPatient : undefined}
                    disabled={isSubmitting || isNewPatient}
                  >
                    <RadioGroup.Option
                      value={patient}
                      className={`${!isNewPatient ? 'cursor-pointer' : ''}`}
                    >
                      {({ checked }) => (
                        <PatientCard
                          fullName={patient.name}
                          firstName={patient.firstName}
                          lastName={patient.lastName}
                          dob={patient.dob}
                          email={patient.email}
                          phoneNumber={patient.phoneNumber}
                          isSelected={!isNewPatient && checked}
                          isNewPatient={isNewPatient}
                          pmsPatient={patient.pmsPatient}
                        >
                          <RadioButton
                            isChecked={!isNewPatient && checked}
                            className={`absolute top-1 left-1 w-1.6 h-1.6 ${
                              isNewPatient ? 'disableIcon' : ''
                            }`}
                          />
                        </PatientCard>
                      )}
                    </RadioGroup.Option>
                  </RadioGroup>
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-x-1.6">
          <CommonButton variant="secondary" onClick={handleOnClose}>
            Cancel
          </CommonButton>
          <CommonButton
            onClick={handleOnSubmit}
            isLoading={isSubmitting}
            disabled={isDisabledMatchPatientBtn}
          >
            Match Patient
          </CommonButton>
        </div>
      </div>
    );
  };

  return (
    <DefaultModal
      handleClose={partialPatients?.length === 0 ? handleOnClose : undefined}
    >
      <div className="w-[57rem] min-h-[42rem]">
        {isDataLoading ? (
          <div className="w-full h-[53rem] flex justify-center items-center">
            <LoadingSpinner className="all-child:fill-magenta" />
          </div>
        ) : partialPatients?.length === 0 ? (
          renderEmptyPartialPatientMatch()
        ) : (
          renderNonEmptyPartialPatientMatch()
        )}
      </div>
    </DefaultModal>
  );
};

export default PartialPatient;
