import { FC } from 'react';
import { ERROR_REASON } from 'utils/constants';
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate.svg';
import { ReactComponent as PartialErrorIcon } from 'assets/icons/practitioners.svg';
import { ReactComponent as WarningErrorIcon } from 'assets/icons/warning.svg';
import { ReactComponent as PatientFamilyFileIcon } from 'assets/icons/patient-family-file.svg';
import { ReactComponent as WriteFailIcon } from 'assets/icons/write-fail.svg';
import { ReactComponent as UnfoundedAvailablePractitioner } from 'assets/icons/unfounded-available-practitioner.svg';
import { ReactComponent as WarningPendingIcon } from 'assets/icons/warning_pending_icon.svg';

interface ErrorReasonProps {
  errorReason: string;
  className?: string;
}

const ErrorReason: FC<ErrorReasonProps> = ({ errorReason, className }) => {
  return (
    <>
      {errorReason === ERROR_REASON.DUPLICATE_BOOKING && (
        <DuplicateIcon className={`w-1.4 h-1.4 ${className}`} />
      )}
      {errorReason === ERROR_REASON.PARTIAL_PATIENT_MATCH && (
        <PartialErrorIcon className={`w-1.4 h-1.4 ${className}`} />
      )}
      {(errorReason === ERROR_REASON.GENERAL_ERROR ||
        errorReason === ERROR_REASON.OUT_OF_OFFICE_HOURS ||
        errorReason === ERROR_REASON.OUT_OF_PRACTITIONER_HOURS) && (
        <WarningErrorIcon className={`w-1.4 h-1.4 ${className}`} />
      )}
      {errorReason === ERROR_REASON.PATIENT_NEED_ADD_TO_FAMILY && (
        <PatientFamilyFileIcon className={`w-1.4 h-1.4 ${className}`} />
      )}
      {errorReason === ERROR_REASON.WRITE_FAIL && (
        <WriteFailIcon className={`w-1.4 h-1.4 ${className}`} />
      )}
      {errorReason === ERROR_REASON.UNFOUNDED_AVAILABLE_PRACTITIONER && (
        <UnfoundedAvailablePractitioner
          className={`w-1.4 h-1.4 ${className}`}
        />
      )}
      {errorReason === ERROR_REASON.WARNING_PENDING && (
        <WarningPendingIcon
          className={`w-1.4 h-1.4 ${className} all-child:!fill-[#F1A809]`}
        />
      )}
    </>
  );
};

export default ErrorReason;
