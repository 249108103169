import React, { FC } from 'react';
import styles from './ErrorIcon.module.css';
import {
  ERROR_REASON,
  PMS_NAME,
  PMS_PENDING_ERROR_MESSAGE,
} from 'utils/constants';
import ReactTooltip from 'react-tooltip';
import ErrorReason from 'components/ErrorReason/ErrorReason';
import { Popover } from '@headlessui/react';
import { getIsWarningIconEnabled } from 'utils/common';
interface ErrorIconProps {
  appointment: any;
}
const ErrorIcon: FC<ErrorIconProps> = ({ appointment, children }) => {
  const errorReason = appointment?.errorReason;
  const id = appointment?.id;

  const isWarningIconEnabled = getIsWarningIconEnabled(appointment);

  const isErrorIconDisplayed = errorReason || isWarningIconEnabled;

  const displayMessage = () => {
    if (isWarningIconEnabled && appointment.pmsName === PMS_NAME.CURVE) {
      return PMS_PENDING_ERROR_MESSAGE[PMS_NAME.CURVE];
    }
    if (isWarningIconEnabled && appointment.pmsName !== PMS_NAME.CURVE) {
      return PMS_PENDING_ERROR_MESSAGE[PMS_NAME.OTHER];
    }
    if (errorReason === ERROR_REASON.DUPLICATE_BOOKING) {
      return 'Duplicate booking.';
    }
    if (errorReason === ERROR_REASON.PARTIAL_PATIENT_MATCH) {
      return 'Partial patient match.';
    }
    if (errorReason === ERROR_REASON.GENERAL_ERROR) {
      return 'This appointment could not be written in the practice software. Please create the appointment in your practice software.';
    }
    if (errorReason === ERROR_REASON.OUT_OF_OFFICE_HOURS) {
      return 'Patient is scheduled outside office hours.';
    }
    if (errorReason === ERROR_REASON.OUT_OF_PRACTITIONER_HOURS) {
      return 'Patient is scheduled outside practitioner’s hours.';
    }
    if (errorReason === ERROR_REASON.PATIENT_NEED_ADD_TO_FAMILY) {
      return 'Please create a family file for this patient in Practice Management Software and wait 15 minutes prior to accepting this appointment.';
    }
    if (errorReason === ERROR_REASON.WRITE_FAIL) {
      return 'Failed to write to PMS.';
    }
    if (errorReason === ERROR_REASON.UNFOUNDED_AVAILABLE_PRACTITIONER) {
      return 'Unable to find an appropriate time slot for this patient.';
    }
  };

  if (!isErrorIconDisplayed) {
    return null;
  }

  return (
    <div className="flex gap-x-1.6">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              data-for={id}
              data-tip=""
              className="w-2.4 h-2.4 bg-white rounded-full flex justify-center items-center shadow-primary shrink-0"
              as="div"
            >
              <ErrorReason
                errorReason={
                  isWarningIconEnabled
                    ? ERROR_REASON.WARNING_PENDING
                    : errorReason
                }
                className={styles.icon}
              />
            </Popover.Button>
            {!open && (
              <ReactTooltip
                id={id}
                place="bottom"
                effect="solid"
                className={`${styles.tooltip} shadow-tooltip`}
              >
                <span className="text-11 text-darkest-grey flex h-full items-center normal-case max-w-[150px]">
                  {displayMessage()}
                </span>
              </ReactTooltip>
            )}
            <Popover.Panel className="absolute z-10" static>
              {children}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ErrorIcon;
