import { FC } from 'react';
import { Listbox } from '@headlessui/react';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';

interface AgeRangeOptionProps {
  value: number;
  onClick: (value: number) => void;
  disabled?: boolean;
  options: number[];
}

const AgeRangeOption: FC<AgeRangeOptionProps> = ({
  value,
  onClick,
  options,
  ...props
}) => {
  return (
    <Listbox {...props}>
      <Listbox.Button className="w-full h-[4.8rem] px-1.6 cursor-pointer rounded-[1rem] bg-white border-[0.5px] text-left outline-none shadow-primary border-lightest-grey hover:border-magenta hover:shadow-input-active focus:border-magenta focus:shadow-input-active relative flex items-center justify-between">
        {value}
        {!props.disabled && <DropdownIcon className="w-1.4 h-1.4 " />}
      </Listbox.Button>
      <Listbox.Options className="absolute w-full rounded-[0.8rem] bg-white text-base shadow-primary flex flex-col gap-y-1.6 z-20 p-2 max-h-[20rem] overflow-auto scrollbar">
        {options.map((item) => (
          <Listbox.Option
            key={item}
            value={item}
            onClick={() => onClick(item)}
            className={({ active, selected }) =>
              `cursor-pointer ${
                active || selected || item === value
                  ? 'text-magenta'
                  : 'text-current'
              }`
            }
          >
            {item}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

export default AgeRangeOption;
