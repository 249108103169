import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import moment from 'moment';
import {
  HeaderGroup,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import ReactTooltip from 'react-tooltip';

import useAppointment, { IAppointmentStatus } from 'hooks/useAppointment';
import ErrorIcon from 'components/ErrorIcon/ErrorIcon';
import { ReactComponent as SortArrow } from 'assets/icons/sort_arrow.svg';
import { ReactComponent as EmptyBucket } from 'assets/icons/empty-bucket.svg';
import { ReactComponent as RemovedInfo } from 'assets/icons/removed-info.svg';
import Pagination, { ITablePaginate } from 'components/Tables/Pagination';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

import { loadAuthToken } from 'utils/storage';
import { capitalizeAvatarName, getErrorReason } from 'utils/common';
import styles from './Table.module.css';
import PractitionerNameColumn from 'components/TableColumn/PractitionerNameColumn/PractitionerNameColumn';
import AppointmentDateTimeColumn from 'components/TableColumn/AppointmentDateTimeColumn/AppointmentDateTimeColumn';
import CSVScheduleInsights from '../CSVScheduleInsights/CSVScheduleInsights';
import CSVExport from '../CSVExport/CSVExport';

const defaultTablePaginationState = {
  currentPage: 1,
  pageSize: 10,
};

interface TableProps {
  clinicId: string | null;
  status: IAppointmentStatus;
  dateRange: { fromDate: string; toDate: string };
  sort: 'asc' | 'desc';
  setSort: Dispatch<SetStateAction<'asc' | 'desc'>>;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  shouldRun: boolean;
}

const Table: FC<TableProps> = ({
  clinicId,
  status,
  dateRange,
  sort,
  setSort,
  sortBy,
  setSortBy,
  shouldRun,
}) => {
  const [pageSize, setPageSize] = useState(
    defaultTablePaginationState.pageSize
  );
  const [currentPage, setCurrentPage] = useState(
    defaultTablePaginationState.currentPage
  );

  const { data, isLoading } = useAppointment({
    startDate: moment(dateRange.fromDate, 'YYYY-MM-DD')
      .startOf('days')
      .utc(true)
      .format(),
    endDate: moment(dateRange.toDate, 'YYYY-MM-DD')
      .endOf('days')
      .utc(true)
      .format(),
    limit: pageSize,
    page: currentPage,
    sort,
    sortBy,
    status,
    // THIS IS TO BYPASS THE INTERCEPTOR
    axiosRequestConfig: {
      headers: {
        Authorization: `JWT ${loadAuthToken()?.accessToken}`,
        ...(clinicId && { 'X-ClinicId': clinicId }),
      },
    },
    shouldRun,
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Appointment Date & Time',
        accessor: (field: any) => {
          return <AppointmentDateTimeColumn data={field} />;
        },
        id: 'appointmentDate',
      },
      {
        Header: 'Patient',
        accessor: (field: any) => {
          return (
            <div className="flex gap-x-0.8 items-center mr-[20px]">
              {field.patient ? (
                <>
                  <div className="w-2.4 h-2.4 bg-magenta rounded-full flex items-center justify-center text-white text-[1rem] shrink-0">
                    {field.patient
                      ? capitalizeAvatarName(
                          `${field.patient.firstName
                            .replace(/\s/g, '')
                            .trim()} ${field.patient.lastName
                            .replace(/\s/g, '')
                            .trim()}`
                        )
                      : {}}
                  </div>
                  <div className="words-break">{field.patient.name}</div>
                </>
              ) : (
                <div />
              )}
            </div>
          );
        },
        id: 'patientName',
      },
      {
        Header: 'Practice',
        accessor: (field: any) => {
          return (
            <div className="words-break mr-[20px]">{field.clinic.name}</div>
          );
        },
        id: 'clinicName',
      },
      {
        Header: 'Practitioner',
        accessor: (field: any) => {
          return <PractitionerNameColumn field={field} />;
        },
        id: 'doctorName',
      },
      {
        Header: 'Service',
        accessor: (field: any) => {
          const isServiceAvailable =
            !field.service.deletedAt &&
            field.service.isActive &&
            field.service.isAvailable;

          return (
            <div className="words-break pr-5">
              {field.service.name}
              {!isServiceAvailable && (
                <>
                  <RemovedInfo
                    className="inline-block ml-0.6"
                    data-for={field.service.id}
                    data-tip=""
                  />
                  <ReactTooltip
                    id={field.service.id}
                    place="bottom"
                    effect="solid"
                    className={`${styles.tooltip} shadow-tooltip`}
                  >
                    <span className="text-11 text-darkest-grey flex h-full items-center normal-case">
                      This service has been deactivated or removed
                    </span>
                  </ReactTooltip>
                </>
              )}
            </div>
          );
        },
        width: 200,
        id: 'serviceName',
      },
      {
        Header: 'Requested Time',
        accessor: (field: any) => {
          return (
            <span>
              {moment(field.createdAt).format('MMM DD, YYYY')}
              <br />
              {moment(field.createdAt).format('hh:mm A')}
            </span>
          );
        },
        id: 'createdAt',
      },
      {
        id: 'error_management',
        width: 50,
        Cell: ({ row }: any) => {
          const errorReason = getErrorReason(row.original);

          return <ErrorIcon appointment={{ ...row.original, errorReason }} />;
        },
      },
    ],
    []
  );

  const isActionColumn = (columnId: string) => columnId !== 'error_management';

  const dataTable = useMemo(() => {
    if (isLoading) {
      return { data: [], metadata: null };
    }
    return { data: data.data, metadata: data.metadata };
  }, [data?.data, data?.metadata, isLoading]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data: dataTable.data ? dataTable.data : [],
        manualPagination: true,
        pageCount: dataTable.metadata ? data.metadata.total : 0,
      },
      useSortBy,
      usePagination,
      useRowSelect,
      useFlexLayout
    );

  const handleSortColumn = (column: HeaderGroup<object>) => {
    if (!isActionColumn(column.id)) return;
    setCurrentPage(1);
    setSortBy(column.id);
    if (column.id === sortBy) {
      return setSort((sort) => (sort === 'asc' ? 'desc' : 'asc'));
    }
    setSort('asc');
  };

  const handlePaginating = (data: ITablePaginate) => {
    setCurrentPage(data.perPage === pageSize ? data.currPage : 1);
    setPageSize(data.perPage);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[37rem] flex justify-center items-center">
                <LoadingSpinner className="all-child:fill-magenta" />
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
    if (data.metadata.total === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[24rem] flex flex-col gap-y-1.6 justify-center items-center">
                <EmptyBucket />
                <span>No appointment available on this list</span>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody {...getTableBodyProps()} className="text-darkest-grey">
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="bg-white border-b border-light-grey px-2 hover:bg-lightPink"
            >
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className={`text-left p-0 py-1.9 my-auto`}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <>
      <div className="flex flex-row gap-x-[1.6rem] absolute right-4 top-2.4">
        <CSVScheduleInsights
          appointmentStatus={status}
          clinicId={clinicId}
          dateRange={dateRange}
          sort={sort}
          sortBy={sortBy}
          isDataLoading={isLoading || !shouldRun}
        />
        <CSVExport
          appointmentStatus={status}
          clinicId={clinicId}
          dateRange={dateRange}
          sort={sort}
          sortBy={sortBy}
          isDataLoading={isLoading || !shouldRun}
        />
      </div>
      <table {...getTableProps()} className="w-full text-14 leading-[2.1rem]">
        <thead className="font-bold">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="px-2">
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    className="text-left p-0 py-1.9"
                  >
                    <div
                      className="flex items-center gap-x-0.9 cursor-pointer"
                      onClick={() => handleSortColumn(column)}
                    >
                      <span className="uppercase text-dim-grey text-11 leading-[1.8rem]">
                        {column.render('Header')}
                      </span>

                      {isActionColumn(column.id) && (
                        <span>
                          <SortArrow
                            className={`${
                              column.id === sortBy && 'fill-magenta'
                            } ${
                              column.id === sortBy &&
                              sort === 'asc' &&
                              'rotate-180'
                            } transition-all`}
                          />
                        </span>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {renderTableBody()}
      </table>
      {dataTable.metadata && dataTable.metadata.total > 0 && (
        <div className="py-1.6">
          <Pagination
            isBorderNotIncluded
            handlePaginating={handlePaginating}
            data={{
              currPage: dataTable.metadata.page,
              perPage: dataTable.metadata.limit,
              total: dataTable.metadata.total,
            }}
          />
        </div>
      )}
    </>
  );
};

export default Table;
