import React from 'react';
import classNames from 'classnames';
import {
  BACKGROUND_COLOR_CODES,
  COLOR_CODES,
  ERROR_REASON,
  EVENT_BACKGROUND_COLOR_CODE,
  PMS_EVENT_TYPE,
  PMS_TYPE,
  TIMEGRID_VIEW,
  TIME_GRID_WORKING_HOUR,
} from 'utils/constants';
import { formatWorkingHour } from 'utils/datetime';
import styles from './View.module.css';
import { IDebugModeDropdown } from '..';
import { ReactComponent as FirstInIcon } from 'assets/icons/firstin-icon.svg';
import { ReactComponent as SplitScheduleIcon } from 'assets/icons/call_split.svg';
import ErrorReason from 'components/ErrorReason/ErrorReason';
import { IPMSAppointment } from 'hooks/usePMSAppointment';
import { getIsWarningIconEnabled } from 'utils/common';
interface INormalEvent {
  fromTime: string;
  toTime: string;
  colorCode: string;
  patientName: string;
  operatoryName: string;
  type: string;
  errorReason: string | null;
  pmsType: string;
  appointmentDetail: IPMSAppointment;
}

interface IAvailableEvent {
  fromTime: string;
  toTime: string;
  service: IDebugModeDropdown['service'];
  practitioner: { id: string; colorCode: string };
  colorCode: string;
  type: string;
  appointmentDetail: IPMSAppointment;
  errorReason: string | null;
}

interface IProps {
  item: INormalEvent | IAvailableEvent;
  selectedView: string;
  paddingTop: number;
  type: string;
  isDebugMode: boolean;
  onClick: () => void;
  onMouseLeave: () => void;
}

const EventCell = (props: IProps) => {
  const {
    item,
    selectedView,
    paddingTop,
    type,
    isDebugMode,
    onClick,
    onMouseLeave,
  } = props;

  const isWarningIconEnabled = getIsWarningIconEnabled(item.appointmentDetail);

  const isErrorIconDisplayed = isWarningIconEnabled || item.errorReason;

  const indexOfColorCode = COLOR_CODES.findIndex(
    (code) => code === item.colorCode
  );

  const isEventCellInNormalMode =
    !isDebugMode && item.type === PMS_EVENT_TYPE.EVENT;

  const isCellNotEvent =
    isDebugMode &&
    [
      PMS_EVENT_TYPE.BLOCKED,
      PMS_EVENT_TYPE.BLOCKED_DOCTOR_HOVERING_EXAM_CLEANING,
    ].includes(type);

  const isCursorDefault = isEventCellInNormalMode || isCellNotEvent;

  const backgroundColor = isDebugMode
    ? null
    : isEventCellInNormalMode
    ? EVENT_BACKGROUND_COLOR_CODE
    : BACKGROUND_COLOR_CODES[indexOfColorCode];

  const renderPractitionerEventCell = () => {
    return (
      <>
        <div className="text-[11px] font-bold leading-[18px] truncateOneLine">
          {(item as INormalEvent).patientName}
        </div>
        <div className="text-[10px] leading-[14px]">
          <span className="truncateOneLine">
            {formatWorkingHour(
              { fromTime: item.fromTime, toTime: item.toTime },
              'hh:mmA'
            )}
          </span>
          <span className="truncateOneLine">
            {(item as INormalEvent).operatoryName}
          </span>
        </div>
      </>
    );
  };

  const renderChairEventCell = () => {
    if (type === PMS_EVENT_TYPE.BLOCKED_DOCTOR_HOVERING_EXAM_CLEANING) {
      return (
        <div className="h-full">
          <div className="text-[11px] font-bold leading-[18px]"></div>
        </div>
      );
    }
    if (
      [
        PMS_EVENT_TYPE.OFFICE_CLOSED_HOUR,
        PMS_EVENT_TYPE.OFFICE_HOLIDAY,
        PMS_EVENT_TYPE.EVENT,
        PMS_EVENT_TYPE.BLOCKED,
      ].includes(type)
    ) {
      // Empty Black Cell
      if (isDebugMode) {
        return (
          <div className="h-full">
            {type === PMS_EVENT_TYPE.EVENT ? (
              <div className="text-[11px] font-bold leading-[18px]">Event</div>
            ) : null}
          </div>
        );
      }
      return (
        <>
          <div className="text-[11px] font-bold leading-[18px] truncateOneLine">
            Event
          </div>
          <div className="text-[10px] leading-[14px]">
            <span className="truncateOneLine">
              {formatWorkingHour(
                { fromTime: item.fromTime, toTime: item.toTime },
                'hh:mmA'
              )}
            </span>
          </div>
        </>
      );
    }

    if (type === PMS_EVENT_TYPE.AVAILABLE_EVENT) {
      return (
        <div className="pl-[8px]">
          <div className="text-[11px] font-bold leading-[18px] truncateOneLine">
            Available
          </div>
          <div className="text-[10px] leading-[14px]">
            <span className="truncateOneLine">
              {formatWorkingHour(
                { fromTime: item.fromTime, toTime: item.toTime },
                'hh:mmA'
              )}
            </span>
            <span className="truncateOneLine">
              {(item as IAvailableEvent).service.name}
            </span>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="text-[11px] font-bold leading-[18px] truncateOneLine">
          {(item as INormalEvent).patientName}
        </div>
        <div className="text-[10px] leading-[14px]">
          <span className="truncateOneLine">
            {formatWorkingHour(
              { fromTime: item.fromTime, toTime: item.toTime },
              'hh:mmA'
            )}
          </span>
        </div>
      </>
    );
  };

  const customStyle = classNames({
    [styles.isNotDebugMode]: !isDebugMode,
    [styles.officeClosedHour]:
      isDebugMode &&
      type === PMS_EVENT_TYPE.OFFICE_CLOSED_HOUR &&
      item.fromTime === TIME_GRID_WORKING_HOUR.START &&
      item.toTime === TIME_GRID_WORKING_HOUR.END,
    [styles.officeHoliday]:
      isDebugMode && type === PMS_EVENT_TYPE.OFFICE_HOLIDAY,
    [styles.event]: isDebugMode && type === PMS_EVENT_TYPE.EVENT,
    [styles.pmsPractitionerHour]:
      isDebugMode && type === PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS,
    [styles.availableEvent]:
      isDebugMode && type === PMS_EVENT_TYPE.AVAILABLE_EVENT,
    [styles.blocked]:
      isDebugMode &&
      (type === PMS_EVENT_TYPE.BLOCKED ||
        type === PMS_EVENT_TYPE.BLOCKED_DOCTOR_HOVERING_EXAM_CLEANING),

    //
  });

  const handleOnClick = () => {
    if (isEventCellInNormalMode) {
      return;
    }
    onClick();
  };

  return (
    <div
      className={`${customStyle} overflow-hidden h-full ${
        isCursorDefault ? '' : 'cursor-pointer'
      }  select-none`}
      style={{
        borderLeftWidth: '4px',
        borderLeftColor: item.colorCode,
        paddingTop: `${paddingTop}px`,
        borderLeftStyle: 'solid',
        ...(backgroundColor && { backgroundColor }),
      }}
      onClick={handleOnClick}
      onMouseLeave={() => {
        if (onMouseLeave) {
          onMouseLeave();
        }
      }}
    >
      {selectedView === TIMEGRID_VIEW.PRACTITIONER
        ? renderPractitionerEventCell()
        : renderChairEventCell()}
      {item.type !== PMS_EVENT_TYPE.AVAILABLE_EVENT && (
        <div className="flex gap-x-0.9  mt-0.5">
          {isErrorIconDisplayed && (
            <ErrorReason
              errorReason={
                isWarningIconEnabled
                  ? ERROR_REASON.WARNING_PENDING
                  : (item as INormalEvent).errorReason!
              }
              className="all-child:fill-secondary-red all-child:all-child:fill-secondary-red"
            />
          )}
          {(item as INormalEvent).pmsType === PMS_TYPE.FIRST_IN && (
            <FirstInIcon />
          )}
          {((item as INormalEvent).appointmentDetail?.isLinkedAppointment ||
            (item as INormalEvent).appointmentDetail?.isSplitScheduling) && (
            <SplitScheduleIcon className="all-child:all-child:fill-[#4D97E1] w-1.4 h-1.4" />
          )}
        </div>
      )}
      {(item as any)?.appointmentDetail?.pmsNote && (
        <div className="text-11 leading-[1.8rem] mt-0.4">
          {(item as any).appointmentDetail.pmsNote}
        </div>
      )}
    </div>
  );
};

export default EventCell;
